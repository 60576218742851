<template>
  <d-remote-monitoring-tasks
    @on-sip-call="onSipCallHandler"
  />
</template>

<script>

export default {
  name: 'RemoteMonitoringTasks',
  page: {
    title: 'CRM Doctis - Дис. мониторинг',
  },
  methods: {
    async onSipCallHandler(payload) {
      await this.$sipService.makeCall(
        payload.phone,
        {
          callerId: this.$store.state.Auth.user.userId,
          ...payload.params,
        },
      );
    },
  },
};
</script>
